<template>
  <MenuItem
    v-for="menu in menuList"
    :key="menu.title"
    :iconClassName="menu.iconClassName"
    :title="menu.title"
    :isCollapse="isCollapse"
    :path="menu.path">
    <template v-slot:menu-item-suffix>
      <Badge
        :title="menu?.badgeText"
        :badgeStyle="menu?.badgeStyle"
        size="small"
        v-if="menu?.badgeStyle && menu?.badgeText" />
    </template>
  </MenuItem>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useStore } from '@/store';
import MenuItem from '@/modules/shared/components/molecules/menuItem/MenuItem.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';

// eslint-disable-next-line no-undef
defineProps({
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const store = useStore();

const teamName = computed(() => route?.params?.teamName);
const projectKey = computed(() => route?.params?.projectKey);
const videoKey = computed(() => route?.params?.videoKey);
const teamFeatures = computed(() => store.state.teamFeatures);

const basePath = `/${teamName.value}/projects/${projectKey.value}/videos/${videoKey.value}`;
const menuList = computed(() => ([
  {
    iconClassName: 'fas fa-info-circle',
    title: 'Information',
    path: `${basePath}/information`,
  },
  ...(teamFeatures.value?.teamFeatures['lighthouse-embeded-dashboard'] ? [{
    iconClassName: 'fas fa-line-chart',
    title: 'Statistics',
    path: `${basePath}/stats`,
  }] : []),
  {
    iconClassName: 'far fa-globe',
    title: 'Geo-blocking',
    path: `${basePath}/geo-blocking`,
  },
  {
    iconClassName: 'fas fa-inbox-out',
    title: 'Video / Audio',
    path: `${basePath}/outputs`,
  },
  {
    iconClassName: 'fas fa-file-export',
    title: 'Exported Files',
    path: `${basePath}/exported-files`,
    badgeText: 'Beta',
    badgeStyle: 'highlight',
  },
  {
    iconClassName: 'fas fa-message-captions',
    title: 'Subtitles',
    path: `${basePath}/subtitles`,
  },
  ...(teamFeatures.value?.teamFeatures['custom-image'] ? [{
    iconClassName: 'fas fa-image',
    title: 'Custom Images',
    path: `${basePath}/custom-images`,
  }] : []),
  ...(teamFeatures.value?.teamFeatures.bumper ? [{
    iconClassName: 'fas fa-wand-magic-sparkles',
    title: 'Bumper',
    path: `${basePath}/bumper`,
  }] : []),
  {
    iconClassName: 'fas fa-list',
    title: 'Advanced',
    path: `${basePath}/advanced`,
  },
]));

</script>

<style lang="scss" scoped>
  :deep(.fa-file-export) {
    margin-right: -4px;
  }
</style>
