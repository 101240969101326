<template>
  <MenuItem
    v-for="(menu,index) in menuList"
    :key="index"
    :iconClassName="menu.iconClassName"
    :title="menu.title"
    :isCollapse="isCollapse"
    :path="menu.path"
    :subMenus="menu.subMenus"
  />
  <MenuItem
    iconClassName="fas fa-bell"
    title="Webhooks"
    :isCollapse="isCollapse"
    :path="`/${teamName}/projects/${projectKey}/manage/webhooks`"
    :isMenuActive="route.name === 'manageWebhookDetails'"
  />
</template>

<script setup>
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import MenuItem from '@/modules/shared/components/molecules/menuItem/MenuItem.vue';

// eslint-disable-next-line no-undef
defineProps({
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const store = useStore();

const teamName = computed(() => route?.params?.teamName);
const projectKey = computed(() => route?.params?.projectKey);
const teamFeatures = computed(() => store.state.teamFeatures);

const getMenuPath = (menu) => {
  const basePath = `/${teamName.value}/projects/${projectKey.value}/manage`;

  switch (menu) {
    case 'details':
      return `${basePath}/general`;
    case 'members':
      return `${basePath}/members`;
    case 'defaults':
      return `${basePath}/defaults`;
    case 'settings':
      return `${basePath}/settings`;
    // case 'webhooks':
    //   return `${basePath}/webhooks`;
    default:
      return null;
  }
};

const defaultSettings = [
  { title: 'Preset', path: `${getMenuPath('defaults')}#preset` },
  { title: 'Geo-blocking', path: `${getMenuPath('defaults')}#geoblocking` },
  ...(teamFeatures.value?.teamFeatures['auto-generate-caption'] ? [{ title: 'Subtitle Profile', path: `${getMenuPath('defaults')}#subtitle` }] : []),
  { title: 'Playback Channel', path: `${getMenuPath('defaults')}#playback` },
];

const projectSettings = [
  { title: 'Video Player', path: `${getMenuPath('settings')}#player` },
  ...(teamFeatures.value?.teamFeatures.bumper ? [{ title: 'Bumper Insertion', path: `${getMenuPath('settings')}#bumperDisplay` }] : []),
];

const menuList = [
  {
    title: 'Project Details',
    iconClassName: 'fas fa-info-circle',
    path: getMenuPath('details'),
  },
  {
    title: 'Members',
    iconClassName: 'fas fa-users',
    path: getMenuPath('members'),
  },
  {
    title: 'Upload Default Settings',
    iconClassName: 'fas fa-cloud-arrow-up',
    path: getMenuPath('defaults'),
    subMenus: defaultSettings,
  },
  {
    title: 'Project Settings',
    iconClassName: 'fas fa-gear',
    path: getMenuPath('settings'),
    subMenus: projectSettings,
  },
  // {
  //   title: 'Webhooks',
  //   iconClassName: 'fas fa-bell',
  //   path: getMenuPath('webhooks'),
  // },
];
</script>
