<template>
  <MenuItem
    iconClassName="fas fa-play-circle"
    title="Videos"
    :isCollapse="isCollapse"
    :path="getMenuPath('videos')"
  />
  <MenuItem
    iconClassName="fas fa-info-circle"
    title="Information"
    :isCollapse="isCollapse"
    :path="getMenuPath('information')"
  />
  <MenuItem
    iconClassName="fas fa-photo-film"
    title="Appearance"
    :isCollapse="isCollapse"
    :path="getMenuPath('appearance')"
  />
  <MenuItem
    iconClassName="fas fa-eye"
    title="Visibility"
    :isCollapse="isCollapse"
    :path="getMenuPath('visibility')"
  />
  <MenuItem
    iconClassName="fas fa-wand-magic-sparkles"
    title="Bumper"
    :isCollapse="isCollapse"
    :path="getMenuPath('bumper')"
  />
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import MenuItem from '@/modules/shared/components/molecules/menuItem/MenuItem.vue';

defineProps({
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const teamName = computed(() => route?.params?.teamName);
const projectKey = computed(() => route?.params?.projectKey);
const contentCollectionId = computed(() => route?.params?.contentCollectionId);

const getMenuPath = (menu) => {
  const basePath = `/${teamName.value}/projects/${projectKey.value}/collections/${contentCollectionId.value}/edit`;

  switch (menu) {
    case 'videos':
      return `${basePath}/videos`;
    case 'information':
      return `${basePath}`;
    case 'appearance':
      return `${basePath}/appearance`;
    case 'visibility':
      return `${basePath}/visibility`;
    case 'bumper':
      return `${basePath}/bumper`;
    default:
      return `${basePath}/videos`;
  }
};
</script>
