export const cutString = (string: unknown, length = 12, suffix = '...'): string => {
  if (typeof string !== 'string') {
    return '';
  }

  if (string && string.length > length) {
    return `${string.substring(0, length)} ${suffix}`;
  }

  return string;
};

export const formatTranslationValueToHTMLList = (translationValue: unknown): string => {
  if (typeof translationValue !== 'string') {
    return '';
  }

  let output = '<ul class="description-bullets">';
  translationValue.split('\n').forEach((string) => {
    output += `<li>${string}</li>`;
  });
  output += '</ul>';

  return output;
};

export function uppercaseOnlyFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
