/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { ActionContext } from 'vuex';
import i18n from '@/plugins/lang/i18n';
import { RootState } from '@/store/type';
import { FormUploadPluginState } from '../types';

type FormUploadPluginActionContext = ActionContext<FormUploadPluginState, RootState>

const initialState = (): FormUploadPluginState => ({
  formId: '',
  formToken: '',
  formAccessToken: '',
  formError: {
    statusCode: null,
    message: '',
  },
});

const state = initialState();

const getters = {
  formAccessToken: (state: FormUploadPluginState) => state.formAccessToken,
  formErrorMessage: (state: FormUploadPluginState) => state.formError.message,
};

const mutations = {
  setFormId(state: FormUploadPluginState, formId: string) {
    state.formId = formId;
  },
  setFormToken(state: FormUploadPluginState, formToken: string) {
    state.formToken = formToken;
  },
  setFormAccessToken(state: FormUploadPluginState, formAccessToken: string) {
    state.formAccessToken = formAccessToken;
  },
  resetFormsState(state: FormUploadPluginState) {
    Object.assign(state, initialState());
  },
  setFormError(state: FormUploadPluginState, error: { status: number }) {
    switch (error.status) {
      case 404:
        state.formError.statusCode = 404;
        state.formError.message = i18n.global.t('formUpload.formId.invalidError');
        break;
      case 422:
        state.formError.statusCode = 422;
        state.formError.message = i18n.global.t('formUpload.token.exp.inputError');
        break;
      case 403:
        state.formError.statusCode = 403;
        state.formError.message = i18n.global.t('formUpload.token.exp.invalidError');
        break;
      case 401:
        state.formError.statusCode = 401;
        state.formError.message = i18n.global.t('formUpload.token.exp.expiredError');
        break;
      default:
        state.formError.statusCode = null;
        state.formError.message = '';
    }
  },
};

const actions = {
  setFormId({ commit }: FormUploadPluginActionContext, formId: string) {
    commit('setFormId', formId);
  },
  setFormToken({ commit }: FormUploadPluginActionContext, formToken: string) {
    commit('setFormToken', formToken);
  },
  setFormAccessToken({ commit }: FormUploadPluginActionContext, formAccessToken: string) {
    commit('setFormAccessToken', formAccessToken);
  },
  resetFormsState({ commit }: FormUploadPluginActionContext) {
    commit('resetFormsState');
  },
  setFormError({ commit }: FormUploadPluginActionContext, error: { status: number }) {
    commit('setFormError', error);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
