import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "router-slot" }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "app-revision-container" }
const _hoisted_6 = ["innerHTML"]

import {
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteLocationNormalizedLoaded, RouteRecordName, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { getCurrentYear } from '@/modules/shared/utils/dateFormatter';
import PageNavBarContainer from '@/components/organisms/pageNavBarContainer/PageNavBarContainer.vue';
import MainNavBar from '@/modules/shared/components/organisms/mainNavBar/MainNavBar.vue';
import teamFeaturesModule from '@/modules/shared/store/teamFeatures';
import type { TeamFeaturesState } from '@/modules/shared/types/teamFeatures.type';
import type { UserState } from '@/modules/user';
import userModule from '@/modules/user/store/user';
import projectListModule from '@/modules/projectList/store';
import PageNotFound from './layouts/PageNotFound.vue';
import Aside from './components/organisms/aside/Aside.vue';
import NavbarContainer from './components/organisms/navbarContainer/NavbarContainer.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const i18n = useI18n();
const route = useRoute();

const store = useStore<{
  teamFeatures: TeamFeaturesState;
  user: UserState;
}>();

store.registerModule<UserState>('user', userModule);
store.registerModule<TeamFeaturesState>('teamFeatures', teamFeaturesModule);
store.registerModule<TeamFeaturesState>('projectList', projectListModule);

const isManagePage = ref(false);
const isOldLayout = ref(false);
const isSidebarToggle = ref(false);
const isPageNavOpen = ref(false);

const language = computed(() => store.state.user.language);
const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const currentRouteName = computed(() => route.name);

const appRevision = computed(() => {
  const REVISION = process.env.VUE_APP_REVISION?.replace(/"/g, '');
  const BRANCH = process.env.VUE_APP_BRANCH?.replace(/"/g, '');
  const COMMITHASH = process.env.VUE_APP_COMMITHASH?.replace(/"/g, '');

  if (process.env.NODE_ENV === 'production') {
    return `Version ${REVISION}#${COMMITHASH}`;
  }

  return `Working on Branch ${BRANCH}#${COMMITHASH}`;
});

function openOrClosePageNavbar() {
  if (window.innerWidth < 992) {
    isPageNavOpen.value = false;
  } else if (currentRouteName.value !== 'projects') {
    isPageNavOpen.value = true;
  } else {
    isPageNavOpen.value = false;
  }
}

function checkLayout(routeValue: RouteLocationNormalizedLoaded) {
  const { fullPath, name } = routeValue;

  const isManage = fullPath.includes('manage') && !fullPath.includes('projects');
  const oldLayout = (!fullPath.includes('projects') && !fullPath.includes('videos')) || name === 'createProject';

  isManagePage.value = isManage;
  isOldLayout.value = oldLayout;

  openOrClosePageNavbar();
}

function onToggleSidebar() {
  isSidebarToggle.value = !isSidebarToggle.value;
}

function isFeatureEnabled(featureKey: string) {
  return teamFeatures.value && teamFeatures.value[featureKey];
}

function isPageEnabled(pageName: RouteRecordName | null | undefined) {
  // Hide premium feature here
  if (pageName === 'subtitleProfiles') {
    return isFeatureEnabled('auto-generate-caption');
  }
  if (
    pageName === 'manageBumper'
    || pageName === 'createBumper'
    || pageName === 'editBumper'
    || pageName === 'manageBumperDisplay'
    || pageName === 'createBumperDisplay'
    || pageName === 'bumperDisplayDetail'
    || pageName === 'editBumperDisplay'
  ) {
    return isFeatureEnabled('bumper');
  }
  if (typeof pageName === 'string' && pageName.includes('custom-image-fields')) {
    return isFeatureEnabled('custom-image');
  }
  return true;
}

watch(isSidebarToggle, () => {
  if (isSidebarToggle.value) {
    document.body.classList.add('sidebar-open');
  } else {
    document.body.classList.remove('sidebar-open');
  }
});

watch(language, (lang) => {
  i18n.locale.value = lang;
});

watch(route, checkLayout);

onMounted(() => window.addEventListener('resize', openOrClosePageNavbar));
onBeforeUnmount(() => window.removeEventListener('resize', openOrClosePageNavbar));

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{ 'manage-page': isManagePage.value, 'new-layout': !isOldLayout.value }, "site-container"])
    }, [
      (!isOldLayout.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(MainNavBar, {
              onOpenPageNavBar: _cache[0] || (_cache[0] = ($event: any) => (isPageNavOpen.value = true))
            }),
            (isPageNavOpen.value)
              ? (_openBlock(), _createBlock(PageNavBarContainer, {
                  key: 0,
                  onClosePageNavBar: _cache[1] || (_cache[1] = ($event: any) => (isPageNavOpen.value = false)),
                  isOpen: isPageNavOpen.value
                }, null, 8, ["isOpen"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (isSidebarToggle.value)
              ? (_openBlock(), _createBlock(Aside, {
                  key: 0,
                  onToggleSidebar: onToggleSidebar
                }))
              : _createCommentVNode("", true),
            _createVNode(NavbarContainer, { onToggleSidebar: onToggleSidebar })
          ], 64)),
      _createElementVNode("div", _hoisted_3, [
        (!isPageEnabled(currentRouteName.value))
          ? (_openBlock(), _createBlock(PageNotFound, { key: 0 }))
          : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      ]),
      (isOldLayout.value)
        ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("small", null, [
                _createTextVNode("© " + _toDisplayString(_unref(getCurrentYear)()) + " ByteArk Stream | All Rights Reserved | ", 1),
                _createElementVNode("span", { innerHTML: appRevision.value }, null, 8, _hoisted_6)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})