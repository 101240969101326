<template>
  <div
    v-if="isShowing"
    class="page-nav-container"
  >
    <div
      v-if="currentPage"
      class="page-nav"
      :class="{
        collapse: isCollapse,
        close: !isOpen,
        [currentPage]: true,
      }"
    >
      <div class="collapse-button-container">
        <CollapseButton
          :isCollapse="isCollapse"
          @on-toggle-collapse="onToggleCollapse" />
      </div>
      <div class="back-navigation">
        <!-- desktop: navigate to previous page -->
        <router-link :to="getPreviousPageURL()" class="back-page-level">
          <i class="fas fa-chevron-left" />
          <span class="back-page-title">{{ getPreviousPageTitle() }}</span>
        </router-link>
        <!-- mobile: nav menu goes back to previuous level, page doesn't change -->
        <!-- until user select a menu -->
        <div class="mobile-back-header-container" :class="{ 'first-page': currentPage === 'projectList' }">
          <div class="back-wrapper">
            <button
              v-if="currentPage !== 'projectList'"
              type="button"
              class="btn back-button"
              @click="onBackNavLevel"
            >
              <i class="fas fa-chevron-left" />
              Back
            </button>
            <router-link
              v-else
              class="navbar-brand"
              :to="`/${teamName}`"
            >
              <div class="brand-container" :class="{ collapse: isCollapse }">
                <img
                  width="28"
                  height="28"
                  src="@/assets/images/logo_120.png"
                  class="logo"
                  alt="ByteArk Stream"
                />
                <span class="brand">Stream</span>
              </div>
            </router-link>
            <button
              type="button"
              class="btn close-button"
              @click="onClosePageNavBar"
            >
              <i class="fas fa-close" />
            </button>
          </div>
        </div>
      </div>
      <div class="page-nav-title" v-if="getPageTitle()">
        {{ getPageTitle() }}
      </div>
      <div class="page-nav-body">
        <div
          v-if="currentPage === 'project'"
          class="menu-wrapper"
          v-motion
          :initial="{ opacity: 0, y: 0 }"
          :enter="{ opacity: 1, y: 0 }"
          :delay="100">
          <ProjectVideosPageNavMenu
            @update:current-page="onUpdatePage"
            :isCollapse="isCollapse"
            :isResponsive="isResponsive"
          />
        </div>
        <div
          v-if="currentPage === 'video'"
          class="menu-wrapper"
          v-motion
          :initial="{ opacity: 0, y: 5 }"
          :enter="{ opacity: 1, y: 0 }"
          :delay="100">
          <VideoDetailPageNavMenu
            :isCollapse="isCollapse" />
        </div>
        <div
          v-if="currentPage === 'projectList'"
          class="menu-wrapper"
          v-motion
          :initial="{ opacity: 0, y: 5 }"
          :enter="{ opacity: 1, y: 0 }"
          :delay="100">
          <ProjectListNavMenu />
        </div>
        <div
          v-if="currentPage === 'collection'"
          class="menu-wrapper"
          v-motion
          :initial="{ opacity: 0, y: 5 }"
          :enter="{ opacity: 1, y: 0 }"
          :delay="100">
          <CollectionNavMenu
            :isCollapse="isCollapse" />
        </div>
        <div
          v-if="currentPage === 'projectManage'"
          class="menu-wrapper"
          v-motion
          :initial="{ opacity: 0, y: 5 }"
          :enter="{ opacity: 1, y: 0 }"
          :delay="100">
          <ProjectManagementNavMenu
            :isCollapse="isCollapse"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- This v-else is for the flexbox to work correctly. -->
  <div v-else />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useBreakpoints } from '@/composables/useBreakpoint';
import {
  ref, computed, watch, toRefs,
} from 'vue';
import { useStore } from '@/store';
import { CollectionNavMenu } from '@/modules/collection';
import CollapseButton from '@/modules/shared/components/atoms/collapseButton/CollapseButton.vue';
import { VideoDetailPageNavMenu } from '@/modules/videoDetail';
import ProjectVideosPageNavMenu from '@/modules/projectVideoList/components/organisms/projectVideosPageNavMenu/ProjectVideosPageNavMenu.vue';
import ProjectListNavMenu from '../projectListNavMenu/ProjectListNavMenu.vue';
import ProjectManagementNavMenu from '../projectManagementNavMenu/ProjectManagementNavMenu.vue';

interface PageNavbarContainerProps {
  isOpen?: boolean;
}

type Page = 'projectList' | 'project' | 'projectManage' | 'video' | 'collection';

const route = useRoute();
const store = useStore();
const { width } = useBreakpoints();

const emits = defineEmits(['closePageNavBar']);

const props = withDefaults(defineProps<PageNavbarContainerProps>(), { isOpen: false });
const { isOpen } = toRefs(props);

const teamName = computed(() => route?.params?.teamName);
const projectKey = computed(() => route?.params?.projectKey);
const lastNavigatedProjectListPage = computed(() => store.state.global.lastNavigatedProjectListPage);
const lastNavigatedVideoListPage = computed(() => store.state.global.lastNavigatedVideoListPage);
const currentProject = computed(() => store.state.project.currentProject);

const isCollapse = ref(true);
const isUserToggle = ref(false);
const currentPage = ref<Page>('projectList');
const isResponsive = ref(false);

const isShowing = computed(() => {
  if (currentPage.value === 'projectList') {
    // Won't show on desktop.
    return width.value < 992;
  }

  if (currentPage.value !== 'project') {
    return true;
  }

  if (route.path.includes('all-projects')) {
    return true;
  }

  return currentProject.value !== null;
});

const getPageTitle = () => {
  switch (currentPage.value) {
    case 'video':
      return 'Video Details';
    case 'projectManage':
      return 'Project Management';
    case 'collection':
      return 'Collection Details';
    default:
      return '';
  }
};

const getPreviousPageTitle = () => {
  switch (currentPage.value) {
    case 'video':
      return 'Videos';
    case 'project':
      return 'Projects';
    case 'collection':
      return 'Collections';
    default:
      return 'Back';
  }
};

const getPreviousPageURL = (): string => {
  switch (currentPage.value) {
    case 'video':
      if (route.query?.from === 'all') {
        return `/${teamName.value}/all-projects`;
      }
      return `/${teamName.value}/projects/${projectKey.value}?page=${lastNavigatedVideoListPage.value}`;
    case 'project':
      return `/${teamName.value}/projects?page=${lastNavigatedProjectListPage.value}`;
    case 'collection':
      return `/${teamName.value}/projects/${projectKey.value}/collections`;
    case 'projectManage':
      return `/${teamName.value}/projects/${projectKey.value}`;
    default:
      return `/${teamName.value}`;
  }
};

const matchPage = (path: string) => {
  if (path.match(new RegExp(`/${teamName.value}/projects/[a-zA-Z0-9-]+/videos`))) {
    currentPage.value = 'video';
  } else if (
    path.match(new RegExp(`/${teamName.value}/projects/[a-zA-Z0-9-]+/collections/[a-zA-Z0-9-]+`))
  ) {
    currentPage.value = 'collection';
  } else if (path.match(`/${teamName.value}/projects/[a-zA-Z0-9-]+/manage`)) {
    currentPage.value = 'projectManage';
  } else if (path.match(new RegExp(`/${teamName.value}/all-projects`))) {
    currentPage.value = 'project';
  } else if (path.match(new RegExp(`/${teamName.value}/projects/[a-zA-Z0-9-]+`))) {
    currentPage.value = 'project';
  } else if (path.match(`/${teamName.value}/projects`)) {
    currentPage.value = 'projectList';
  }
};

const onBackNavLevel = () => {
  switch (currentPage.value) {
    case 'video':
      currentPage.value = 'project';
      break;
    case 'collection':
      currentPage.value = 'project';
      break;
    case 'projectManage':
      currentPage.value = 'project';
      break;
    case 'project':
      currentPage.value = 'projectList';
      break;
    default:
      break;
  }
};

const onClosePageNavBar = () => {
  matchPage(route.path); // reset to current page in case of back
  emits('closePageNavBar');
};

const onUpdatePage = (page: Page) => {
  currentPage.value = page;
};

const onToggleCollapse = () => {
  isCollapse.value = !isCollapse.value;
  isUserToggle.value = true;
};

watch(
  () => route.path,
  () => {
    matchPage(route.path);
  },
  { immediate: true },
);

watch(
  () => width.value,
  () => {
    // prevent default collapse/expand if user toggle navbar
    if (width.value <= 1199 && width.value > 992 && !isUserToggle.value && !isCollapse.value) {
      isCollapse.value = true;
    }
    if (width.value >= 1200 && !isUserToggle.value && isCollapse.value) {
      isCollapse.value = false;
    }
    if (width.value < 992) {
      // no collapse in responsive
      isResponsive.value = true;
      if (isCollapse.value) {
        isCollapse.value = false;
      }
    }
  },
  { immediate: true },
);

watch(
  () => width.value,
  () => {
    // prevent default collapse/expand if user toggle navbar
    if (width.value <= 1199 && width.value > 992 && !isUserToggle.value && !isCollapse.value) {
      isCollapse.value = true;
    }
    if (width.value >= 1200 && !isUserToggle.value && isCollapse.value) {
      isCollapse.value = false;
    }
    if (width.value < 992) {
      // no collapse in responsive
      isResponsive.value = true;
      if (isCollapse.value) {
        isCollapse.value = false;
      }
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.page-nav {
  background-color: $white;
  border-right: 1px solid $grey-200;
  width: 300px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: all 0.3s ease;

  @media screen and (min-width: $min-layout-lg) {
    &.project {
      width: 230px;
    }

    &.video {
      width: 220px;
    }

    &.collection {
      width: 200px;
    }

    &.projectManage {
      width: 280px;
    }
  }

  &.close {
    @media screen and (max-width: $max-layout-lg) {
      display: none;
    }
  }

  .collapse-button-container {
    position: absolute;
    right: -18px;
    bottom: 25%;
    z-index: 5;
  }

  :deep(.collapse-button) {
    background-color: white;
    border-color: $grey-200;
    color: $grey-600;
  }

  .page-nav-title {
    color: $grey-800;
    font-weight: $font-weight-bold;
    font-size: $font-level-6;
    padding: $spacing-12 $spacing-base;
  }

  .back-navigation {
    width: 100%;
    cursor: pointer;

    a {
      color: $grey-600;
    }

    .back-page-level {
      display: flex;
      width: 100%;
      align-items: baseline;
      padding: $spacing-24 $spacing-base $spacing-12 $spacing-base;
      gap: $spacing-8;
    }

    .mobile-back-header-container {
      display: none;
    }

    .navbar-brand {
      font-size: $font-level-5;
      color: $grey-800;
      font-weight: $font-weight-bold;

      .brand-container {
        display: flex;
        gap: $spacing-8;
        align-items: center;

        .brand {
          margin-top: 10px;
        }

        &.collapse {
          .brand {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: $max-layout-lg) {
      .back-page-level {
        display: none;
      }

      .mobile-back-header-container {
        display: block;
        width: 100%;
        padding: 12px 8px 12px 16px;
        color: $grey-600;
        font-size: 14px;

        .btn {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          color: inherit;
          font-size: inherit;
          min-width: 33px;
          min-height: 33px;
          padding: 0;
        }

        .back-wrapper {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          height: 33px;
        }

        .back-button {
          justify-content: flex-start;

          .fa-chevron-left {
            min-width: 16px;
            margin-right: 4px;
          }
        }

        .close-button {
          font-size: 16px;
          justify-content: center;
          flex: 0 0 33px;
        }

        &.first-page {
          border-bottom: 1px solid $grey-200;
        }
      }
    }
  }

  .page-nav-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
    }
  }

  &.collapse {
    width: 65px;

    .page-nav-title,
    .back-page-title {
      display: none;
    }

    .back-page-level {
      justify-content: center;
      padding: $spacing-24 $spacing-base $spacing-24 $spacing-base;
    }
  }
}

@media screen and (max-width: $max-layout-lg) {
  .page-nav{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 4;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  }
}
</style>
