<template>
  <div class="menu">
    <router-link :to="`/${teamName}/me`" class="menu-item menu-item-username">
      <div class="menu-item-title">
        <h6 class="menu-item-displayname">{{currentUser.name}}</h6>
        <div class="menu-item-email">
          {{currentUser.email}}
          <Badge
            :title="uppercaseOnlyFirstLetter(currentUser?.role)"
            theme="dark"
            size="small"
            :badgeStyle="(currentUser?.role === 'manager' || currentUser?.role === 'admin') ? 'highlight' : 'secondary'"
          />
        </div>
      </div>
    </router-link>
    <Button
      v-for="button in externalButtons"
      :key="button.url + button.label"
      isExternalUrl
      buttonStyle="text-secondary"
      :url="button.url"
    >
      <div class="menu-item">
        <div class="menu-item-title">
          <div class="menu-item-icon">
            <i :class="button.icon"></i>
          </div>
          <span class="menu-item-name">{{ button.label }}</span>
        </div>
        <i class="fas fa-external-link" />
      </div>
    </Button>
    <div class="divider" />
    <router-link :to="`/${teamName}/me/personal-access-token`" class="menu-item">
      <div class="menu-item-title">
        <div class="menu-item-icon">
          <i class="fa-solid fa-user-shield"></i>
        </div>
        <span class="menu-item-name">Personal Access Token</span>
      </div>
    </router-link>
    <div class="divider" />
    <div class="menu-item menu-item-lang">
      <div class="menu-item-title">
        <div class="menu-item-icon">
          <i class="fa-regular fa-globe"></i>
        </div>
        <span class="menu-item-name">{{ $t('common:switch.lang') }}</span>
      </div>
      <div class="suffix">
        <ManageAccountLanguage />
      </div>
    </div>
    <div class="divider" />
    <a :href="`${baseURL}/accounts/logout?team=${teamName}`" class="menu-item">
      <div class="menu-item-title">
        <div class="menu-item-icon">
          <i class="fas fa-right-from-bracket" />
        </div>
        <span class="menu-item-name">{{ $t('common:button.logout') }}</span>
      </div>
    </a>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useStore } from '@/store';
import { computed } from 'vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import ManageAccountLanguage from '@/components/molecules/manageAccountLanguage/ManageAccountLanguage.vue';
import { uppercaseOnlyFirstLetter } from '@/modules/shared/utils/textFormatter';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const store = useStore();
const { t } = useI18n();

const teamName = computed(() => route?.params?.teamName);
const baseURL = computed(() => process.env.VUE_APP_ORIGIN || window.location.origin);

const manageProfileUrl = computed(() => `https://accounts.byteark.com/${teamName.value}/profile`);
const managePasswordUrl = computed(() => `https://accounts.byteark.com/${teamName.value}/profile#password`);
const currentUser = computed(() => store.state.user.currentUser);

const externalButtons = computed(() => [
  {
    url: manageProfileUrl.value,
    icon: 'fa-solid fa-user',
    label: t('common:edit.profile'),
  },
  {
    url: managePasswordUrl.value,
    icon: 'fa-solid fa-key',
    label: t('common:change.password'),
  },
]);

</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables.scss';

.menu {
  min-width: 280px;
  margin-bottom: $spacing-8;
  background-color: white;
  border: 1px solid $grey-200;
  border-radius: 4px;
  z-index: 102;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

  :deep(.button) {
    padding: 0;
  }
}

.menu-item {
  width: 100%;
  padding: $spacing-base $spacing-base $spacing-base $spacing-base;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-level-7;
  line-height: 120%;
  cursor: pointer;
  color: $grey-600;

  .menu-item-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-8;
    white-space: nowrap;

    .menu-item-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
    }
  }

  &:not(.disable-hover) {
    &:hover {
      background-color: $grey-100;
    }
  }
}

.menu-item-username {
  border-bottom: 1px solid $grey-200;
  padding-bottom: $spacing-12;
  align-items: baseline;
  width: 100%;
  .menu-item-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .menu-item-displayname {
    white-space: pre-line;
    word-break: break-all;
  }

  .menu-item-email {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  :deep(.badge) {
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $grey-200;
}
</style>
