<template>
  <nav class="navbar navbar-expand-lg">
    <Button
      type="button"
      buttonStyle="text-secondary-light"
      buttonClasses="navbar-toggler"
      aria-label="Toggle navigation"
      @click.stop="onToggleMenu"
    >
      <template v-slot:icon-prefix>
        <i class="fas fa-bars"></i>
      </template>
    </Button>
    <slot name="navbar-brand" v-if="$slots['navbar-brand']" />
    <slot name="navbar-nav-actions" v-if="$slots['navbar-nav-actions']" />
    <div class="collapse navbar-collapse" v-if="$slots['navbar-nav']">
      <slot name="navbar-nav" />
    </div>
    <slot name="navbar-nav-user" v-if="$slots['navbar-nav-user']"> test </slot>
  </nav>
</template>

<script>
import Button from '@/modules/shared/components/atoms/button/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    onToggleMenu: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    teamName() {
      return this.$route.params.teamName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  .navbar-collapse {
    display: none;
  }
}

@media screen and (min-width: $min-layout-lg) {
  .navbar {
    :deep(.navbar-toggler) {
      display: none;
    }

    .navbar-collapse {
      display: flex;
      flex-basis: auto;
      align-items: center;
      flex: 1;
    }
  }
}
</style>
