<template>
  <div class="main-nav-desktop" :class="{ collapse: isCollapse }">
    <div class="collapse-button-container">
      <CollapseButton :isCollapse="isCollapse" @on-toggle-collapse="onToggleCollapse" />
    </div>
    <div class="main-nav-header">
      <router-link class="navbar-brand desktop" :to="`/${teamName}/projects`">
        <div class="brand-container" :class="{ collapse: isCollapse }">
          <img
            width="30"
            height="30"
            src="@/assets/images/logo_120.png"
            class="logo"
            alt="ByteArk Stream"
          />
          <span class="brand">Stream</span>
        </div>
      </router-link>
      <div class="divider" />
    </div>
    <div class="main-nav-body">
      <template v-if="teamName">
        <div class="menu">
          <MenuItem
            title="Videos"
            icon-class-name="fas fa-play-circle"
            :isMenuActive="isVideoMenuActive"
            :path="projectVideoPath"
            :isCollapse="isCollapse"
            isPrimary
          />
          <MenuItem
            title="Projects"
            icon-class-name="fas fa-folder-open"
            :isMenuActive="isProjectMenuActive"
            :path="{ name: 'projectList' }"
            :isCollapse="isCollapse"
            isPrimary
          />
        </div>
        <div class="main-nav-footer">
          <a
            href="https://docs.byteark.com/th/stream"
            rel="noreferrer nofollow"
            target="_blank"
          >
            <MenuItem
              title="Docs"
              icon-class-name="fas fa-file-lines"
              :isCollapse="isCollapse"
              isPrimary
              isExternalLink
            />
          </a>
          <MenuItem
            title="Manage"
            icon-class-name="fas fa-screwdriver-wrench"
            :isCollapse="isCollapse"
            :path="{name: 'geoBlocking'}"
            isPrimary
          />
          <div class="divider" />
          <Popper arrow placement="right">
            <MenuItem :title="currentUser?.name" :isCollapse="isCollapse" isPrimary class="menu-item-user">
              <template v-slot:menu-item-prefix>
                <div class="user-avatar">
                  <Logo :user="currentUser" />
                </div>
              </template>
            </MenuItem>
            <template v-slot:content>
              <UserMenu />
            </template>
          </Popper>
        </div>
      </template>
    </div>
  </div>
  <div class="main-nav-responsive">
    <template v-if="teamName">
      <i class="fas fa-bars hamburger" @click="onOpenPageNavBar" />
      <router-link class="navbar-brand" :to="`/${teamName}/projects`">
        <div class="brand-container" :class="{ collapse: isCollapse }">
          <img
            width="30"
            height="30"
            src="@/assets/images/logo_120.png"
            class="logo"
            alt="ByteArk Stream"
          />
          <span class="brand">Stream</span>
        </div>
      </router-link>
      <Popper arrow placement="bottom">
        <Logo :user="currentUser" />
        <template v-slot:content>
          <UserMenu />
        </template>
      </Popper>
    </template>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import {
  ref, watch, computed, onBeforeMount,
} from 'vue';
import { useStore } from '@/store';
import { useBreakpoints } from '@/composables/useBreakpoint';
import CollapseButton from '@/modules/shared/components/atoms/collapseButton/CollapseButton.vue';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import MenuItem from '@/modules/shared/components/molecules/menuItem/MenuItem.vue';
import UserMenu from '@/components/organisms/userMenu/UserMenu.vue';
import Popper from 'vue3-popper';

const emits = defineEmits(['openPageNavBar']);

const route = useRoute();
const store = useStore();
const { width } = useBreakpoints();

const teamName = computed(() => route?.params?.teamName);
const currentUser = computed(() => store.state.user?.currentUser);

const isProjectMenuActive = computed(() => {
  const { path } = route;
  return !_.isEmpty(path.match(`/${teamName.value}/projects/[a-zA-Z0-9-]+/manage`));
});

const isVideoMenuActive = computed(() => {
  const { path } = route;
  return !isProjectMenuActive.value && !_.isEmpty(path.match(new RegExp(`/${teamName.value}/projects/[a-zA-Z0-9-]+`)));
});

const projectVideoPath = computed(() => {
  const lastVisitedProject = localStorage.getItem('projectKey');
  const lastTeamName = localStorage.getItem('teamName');
  if (lastTeamName === teamName.value && lastVisitedProject) {
    if (lastVisitedProject === 'all') {
      return ({ name: 'allProjectVideos' });
    }
    return ({ name: 'projectVideos', params: { projectKey: lastVisitedProject } });
  }
  return { name: 'allProjectVideos' };
});

const isCollapse = ref(false);
const isUserToggle = ref(false); // expand by user, remains expand

const onOpenPageNavBar = () => {
  emits('openPageNavBar');
};

const onToggleCollapse = () => {
  isCollapse.value = !isCollapse.value;
  isUserToggle.value = true;
};

watch(
  () => width.value,
  () => {
    // prevent default collapse/expand if user toggle
    if (width.value < 1199 && width.value > 992 && !isUserToggle.value && !isCollapse.value) {
      isCollapse.value = true;
    }
    if (width.value < 992 && isCollapse.value) {
      isCollapse.value = false;
    }
  },
  { immediate: true },
);

watch(
  () => route?.path,
  () => {
    if (!isUserToggle.value) {
      if (
        route?.path !== `/${teamName.value}/projects`
        && !isUserToggle.value
        && !isCollapse.value
      ) {
        isCollapse.value = true;
      } else if (route?.path === `/${teamName.value}/projects` && !isUserToggle.value) {
        isCollapse.value = false;
      }
    }
  },
);

onBeforeMount(() => {
  if (route?.path !== `/${teamName.value}/projects`) {
    isCollapse.value = true;
  }
});
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.main-nav-desktop {
  background-color: $bg-navy;
  width: 180px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease;

  &.collapse{
    width: 68px;
  }

  .collapse-button-container {
    position: absolute;
    right: -18px;
    bottom: 25%;
    z-index: 5;
  }

  @media screen and (max-width: $max-layout-lg) {
    display: none;
  }

  .divider {
    height: 1px;
    background-color: $grey-700;
    margin: $spacing-12;
  }

  .navbar-brand {
    font-size: 14px;
    color: white;

    .brand-container {
      display: flex;
      gap: $spacing-8;
      align-items: flex-end;
      text-align: center;
      padding: $spacing-12 $spacing-base $spacing-4 $spacing-base;

      .brand {
        line-height: 21px;
        font-size: $font-level-5;
        font-weight: $font-weight-bold;
        white-space: nowrap;
        small{
          font-size: $font-level-8;
          font-weight: $font-weight-base;
        }
      }

      &.collapse {
        justify-content: center;

        .brand {
          display: none;
        }
      }
    }
  }

  .main-nav-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .main-nav-footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .user-profile {
      display: flex;
      gap: $spacing-8;
      color: white;
      padding: 0px $spacing-base $spacing-12 $spacing-base;
      align-items: center;
    }

    @media screen and (max-width: $max-layout-lg) {
      display: none;
    }
  }

  .menu-item-user {

    :deep(.menu-item-title){
      overflow: hidden;
    }

    :deep(.menu-item-name) {
      display: block;
      white-space: nowrap;
      overflow: hidden;
    }

    :deep(.is-collapse) {
      justify-content: flex-start !important;
    }
  }
}

.main-nav-responsive {

  @media screen and (min-width: $min-layout-lg) {
    display: none;
  }

  display: flex;
  height: 57px;
  width: 100%;
  padding: 0px $spacing-base;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-navy;
  font-size: 20px;
  color: $grey-100;

  .navbar-brand {
    font-size: 16px;
    color: white;

    .brand-container {
      display: flex;
      gap: $spacing-8;
      justify-content: center;
      align-items: center;

      .logo {
        width: 27px;
        height: 27px;
      }
    }

    .brand {
      font-size: 18px;
      margin-top: 6px;
    }
  }

  .hamburger {
    cursor: pointer;
  }
}
// custom style popper
:deep(.popper) {
  margin-left: -($spacing-base) !important;
}
</style>
