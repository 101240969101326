<template>
  <div id="app">
    <div
      :class="{ 'manage-page': isManagePage, 'new-layout': !isOldLayout }"
      class="site-container"
    >
      <template v-if="!isOldLayout">
        <MainNavBar @openPageNavBar="isPageNavOpen = true" />
        <PageNavBarContainer
          v-if="isPageNavOpen"
          @closePageNavBar="isPageNavOpen = false"
          :isOpen="isPageNavOpen"
        />
        <!-- DO NOT DELETE, USED BY GRID-TEMPLATE-COLUMN -->
        <div v-else />
      </template>
      <template v-else>
        <Aside v-if="isSidebarToggle" :onToggleSidebar="onToggleSidebar" />
        <NavbarContainer :onToggleSidebar="onToggleSidebar" />
      </template>
      <div class="router-slot">
        <PageNotFound v-if="!isPageEnabled(currentRouteName)" />
        <router-view v-else />
      </div>
      <footer v-if="isOldLayout">
        <div class="app-revision-container">
          <small>© {{ getCurrentYear() }} ByteArk Stream | All Rights Reserved | <span v-html="appRevision" /></small>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteLocationNormalizedLoaded, RouteRecordName, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { getCurrentYear } from '@/modules/shared/utils/dateFormatter';
import PageNavBarContainer from '@/components/organisms/pageNavBarContainer/PageNavBarContainer.vue';
import MainNavBar from '@/modules/shared/components/organisms/mainNavBar/MainNavBar.vue';
import teamFeaturesModule from '@/modules/shared/store/teamFeatures';
import type { TeamFeaturesState } from '@/modules/shared/types/teamFeatures.type';
import type { UserState } from '@/modules/user';
import userModule from '@/modules/user/store/user';
import projectListModule from '@/modules/projectList/store';
import PageNotFound from './layouts/PageNotFound.vue';
import Aside from './components/organisms/aside/Aside.vue';
import NavbarContainer from './components/organisms/navbarContainer/NavbarContainer.vue';

const i18n = useI18n();
const route = useRoute();

const store = useStore<{
  teamFeatures: TeamFeaturesState;
  user: UserState;
}>();

store.registerModule<UserState>('user', userModule);
store.registerModule<TeamFeaturesState>('teamFeatures', teamFeaturesModule);
store.registerModule<TeamFeaturesState>('projectList', projectListModule);

const isManagePage = ref(false);
const isOldLayout = ref(false);
const isSidebarToggle = ref(false);
const isPageNavOpen = ref(false);

const language = computed(() => store.state.user.language);
const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const currentRouteName = computed(() => route.name);

const appRevision = computed(() => {
  const REVISION = process.env.VUE_APP_REVISION?.replace(/"/g, '');
  const BRANCH = process.env.VUE_APP_BRANCH?.replace(/"/g, '');
  const COMMITHASH = process.env.VUE_APP_COMMITHASH?.replace(/"/g, '');

  if (process.env.NODE_ENV === 'production') {
    return `Version ${REVISION}#${COMMITHASH}`;
  }

  return `Working on Branch ${BRANCH}#${COMMITHASH}`;
});

function openOrClosePageNavbar() {
  if (window.innerWidth < 992) {
    isPageNavOpen.value = false;
  } else if (currentRouteName.value !== 'projects') {
    isPageNavOpen.value = true;
  } else {
    isPageNavOpen.value = false;
  }
}

function checkLayout(routeValue: RouteLocationNormalizedLoaded) {
  const { fullPath, name } = routeValue;

  const isManage = fullPath.includes('manage') && !fullPath.includes('projects');
  const oldLayout = (!fullPath.includes('projects') && !fullPath.includes('videos')) || name === 'createProject';

  isManagePage.value = isManage;
  isOldLayout.value = oldLayout;

  openOrClosePageNavbar();
}

function onToggleSidebar() {
  isSidebarToggle.value = !isSidebarToggle.value;
}

function isFeatureEnabled(featureKey: string) {
  return teamFeatures.value && teamFeatures.value[featureKey];
}

function isPageEnabled(pageName: RouteRecordName | null | undefined) {
  // Hide premium feature here
  if (pageName === 'subtitleProfiles') {
    return isFeatureEnabled('auto-generate-caption');
  }
  if (
    pageName === 'manageBumper'
    || pageName === 'createBumper'
    || pageName === 'editBumper'
    || pageName === 'manageBumperDisplay'
    || pageName === 'createBumperDisplay'
    || pageName === 'bumperDisplayDetail'
    || pageName === 'editBumperDisplay'
  ) {
    return isFeatureEnabled('bumper');
  }
  if (typeof pageName === 'string' && pageName.includes('custom-image-fields')) {
    return isFeatureEnabled('custom-image');
  }
  return true;
}

watch(isSidebarToggle, () => {
  if (isSidebarToggle.value) {
    document.body.classList.add('sidebar-open');
  } else {
    document.body.classList.remove('sidebar-open');
  }
});

watch(language, (lang) => {
  i18n.locale.value = lang;
});

watch(route, checkLayout);

onMounted(() => window.addEventListener('resize', openOrClosePageNavbar));
onBeforeUnmount(() => window.removeEventListener('resize', openOrClosePageNavbar));
</script>

<style lang="scss">
@import '~@/assets/scss/app.scss';
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.page-header-container {
  background-color: $bg-navy;
  padding: 3rem 0 0.5rem;
  .page-header {
    padding: 2rem 0 1rem;
  }
}

.loading-container .page-header-container {
  .animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: $bg-navy;
    background: linear-gradient(to right, #24313c, #2c3b48, #24313c);
    background-size: 50% 104px;
    position: relative;
    height: 52px;
    width: 30%;
    min-width: 200px;
  }

  .background-masker {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
  }
}

.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.new-layout {
    display: grid;
    grid-template-columns: auto auto 1fr;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
  }

  &.manage-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &.sidebar-open {
    margin-left: 320px;
  }

  &.sidebar-collapsed {
    margin-left: 54px;
  }

  .router-slot {
    position: relative;
    flex: 1 0 auto;
    z-index: 3; /* Prevent footer's app-revision-container from displaying above fullscreen modals */
  }
}

footer {
  color: $grey-500;

  .app-revision-container {
    text-align: right;
    margin-right: $spacing-base * 0.75;
    padding-bottom: $spacing-base * 0.5;
    position: relative;
    z-index: -1;
  }
}

.aside-wrapper {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  background-color: $grey-100;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.full-screen {
  background-color: $grey-100;
}

@media screen and (max-width: $max-layout-xl) {
  .manage-page .container {
    max-width: 96vw;
  }
}

@media screen and (max-width: $max-layout-lg) {
  .filter-sidebar-open {
    margin-right: 0;
  }
  .site-container {
    &.new-layout {
      grid-template-columns: 1fr;
      grid-template-rows: 57px 1fr;
    }
  }

  .manage-page .container {
    max-width: 98vw;
  }

  footer {
    .app-revision-container {
      text-align: center;
      margin: 0 auto;
      width: 80%;
    }
  }
}

@media screen and (max-width: $max-layout-sm) {
  .loading-container .title {
    margin-bottom: $spacing-base * 1.425;
  }
  .page-header-container {
    padding-top: $spacing-base * 2;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -50% 0;
  }
  100% {
    background-position: 50% 0;
  }
}
</style>
