<template>
  <div class="collapse-container" @click="toggleCollapse">
    <div class="collapse-button">
      <i v-if="!isCollapse" class="fas fa-angle-double-left" />
      <i v-else class="fas fa-angle-double-right" />
    </div>
  </div>
</template>

<script setup>
const emits = defineEmits(['onToggleCollapse']);

defineProps({
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

const toggleCollapse = () => {
  emits('onToggleCollapse');
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.collapse-container {
  padding: $spacing-8;
  cursor: pointer;
}

.collapse-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid $grey-700;
  background-color: $bg-navy;
  font-size: 12px;
  color: #ffffff;

  i {
    margin-top: 1px;
  }

  @media screen and (max-width: $max-layout-lg) {
    display: none;
  }
}
</style>
