<template>
  <div class="language-list">
    <div
      class="language-choice"
      :class="{'active': isLanguageActive('th')}"
      @click="onChangeLang('th')">
      ไทย
      <i class="fas fa-check check-icon"></i>
    </div>
    <div
      class="language-choice"
      :class="{'active': isLanguageActive('en')}"
      @click="onChangeLang('en')">
      EN
      <i class="fas fa-check check-icon"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      language: (state) => state.user.language,
    }),
  },
  methods: {
    isLanguageActive(lang) {
      return this.language === lang;
    },
    onChangeLang(lang) {
      this.$store.dispatch('updateCurrentUserLanguage', lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.language-list {
  display: flex;
  justify-content: center;
  column-gap: $spacing-8;

  .language-choice {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    align-items: center;
    text-align: center;
    font-size: $font-level-8;
    padding: $spacing-4 $spacing-base;
    transition: all 0.2s ease-out;
    color: $grey-600;
    border: 1px solid #e3e4e7;
    border-radius: 100px;
    white-space: nowrap;

    .flag {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: 4px;

      &.flag-th {
        background: url("~@/assets/images/TH.svg") no-repeat 50% 50%;
        background-size: 120%;
      }

      &.flag-en {
        background: url("~@/assets/images/EN.svg") no-repeat 50% 50%;
        background-size: 120%;
      }
    }

    .check-icon {
      display: none;
      margin-left: 5px;
      color: $ci-primary;
    }

    &:hover {
      color: $body-color;
      border: 1px solid #dadce0;
      background-color: $grey-100;
    }

    &.active {
      color: $ci-primary;
      border: 1px solid $ci-primary;
      background-color: lighten($ci-primary, 40%);
      cursor: default;

      .check-icon {
        display: inline-block;
      }

      &:hover {
        cursor: default;
      }
    }
  }
}

</style>
