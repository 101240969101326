import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

// Plugins
import ByteArkPlayerContainer from '@byteark/byteark-player-vue';
import VideoFileDraggable from '@/directives/videoFileDraggable';
import Datepicker from '@vuepic/vue-datepicker';
import { MotionPlugin } from '@vueuse/motion';
import posthog from 'posthog-js';
import VueMultiselect from 'vue-multiselect';
import { PrismEditor } from 'vue-prism-editor';
import vClickOutside from 'click-outside-vue3';
import CircleProgress from 'vue3-circle-progress';
import ToastPlugin from 'vue-toast-notification';
import i18n from './plugins/lang/i18n';

import App from './App.vue';
import router from './router';
import store, { storeKey } from './store';

const app = createApp(App);

app.use(router);
app.use(store, storeKey);
app.use(i18n);
app.use(MotionPlugin);
app.use(ToastPlugin);

app.component('ByteArkPlayerContainer', ByteArkPlayerContainer);
app.component('multiselect', VueMultiselect);
app.component('prism-editor', PrismEditor);
app.component('vue-circle', CircleProgress);
app.component('DatePicker', Datepicker);

app.directive('click-outside', vClickOutside.directive);
app.directive('video-file-draggable', VideoFileDraggable);

app.config.globalProperties.i18n = i18n;
app.config.globalProperties.$posthog = posthog.init(
  'phc_grlCVPfCKfIDtvAxruoUm7ELSwe8D0JzQEbuNgQnDU3',
  {
    api_host: 'https://app.posthog.com',
  },
);

if (process.env.SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
  });
}

app.mount('#app');
